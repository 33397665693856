'use strict';

import http from '@/utils/http';
export default {
  pageUrl: '/marketing/namecardInfo/page',

  // 数据总览
  dateOverviewe(code, params) {
    return http.get(`/marketing/namecardInfo/stat`, { params, code });
  },


  add(code, data) {
    return http.post('/marketing/namecardTemplate', data, { code });
  },
  update(code, data) {
    return http.put('/marketing/namecardTemplate', data, { code });
  },

  // 名片列表
  getTemplateList(code) {
    return http.get('/marketing/namecardTemplate/list', {
      code,
    });
  },

  cardDelete(code, params) {
    return http.delete('/marketing/namecardTemplate', { params, code });
  },







  // 推广添加
  promoteAdd(code, data) {
    return http.post('/marketing/iradar/manager/save', data, { code });
  },
  promoteUpdate(code, data) {
    return http.put('/marketing/marketingPromote/update', data, { code });
  },
  promoteDelete(code, id) {
    return http.delete(`/marketing/iradar/manager/${id}`, { code });
  },
  promoteDataPreview(code, id) {
    // 资料预览
    return http.get(`/resource/resourceFile/marketing/preview/${id}`, {
      code,
    });
  },
  getByFileId(code, id) {
    // 根据文章id查询名字
    return http.get(`/resource/resourceCategory/getByFileId/${id}`, {
      code,
    });
  },

  posterPageUrl: '/marketing/marketingPoster/page', // 海报分页

  posterAdd(code, data) {
    return http.post('/marketing/marketingPoster/add', data, { code });
  },
  posterUpdate(code, data) {
    return http.put('/marketing/marketingPoster/update', data, { code });
  },
  posterDelete(code, params) {
    return http.delete('/marketing/marketingPoster', { params, code });
  },

  // 推广人员
  promoteUserPageUrl: '/marketing/marketingPromoteUser/page', // 海报分页、推广者账号分页
  communicatorsUrl: '/marketing/marketingPromoteUser/page/communicateUser',//传播者分页
  promoteUserAdd(code, data) {
    return http.post('/marketing/marketingPromoteUser', data, { code });
  },
  promoteUserUpdate(code, data) {
    return http.put('/marketing/marketingPromoteUser', data, { code });
  },
  promoteUserDelete(code, params) {
    return http.delete('/marketing/marketingPromoteUser', { params, code });
  },
  getOverView(code) {
    // 资料预览
    return http.get(`/marketing/marketingPromoteUser/getOverView`, {
      code,
    });
  },

  // 推广人员
  promoteDataPageUrl: '/marketing/marketingPromoteData/page', // 海报分页
  promoteDataExportItem(code, id) {
    // 导出
    return http.get(`/marketing/marketingPromoteData/exportItem/${id}`, {
      responseType: 'blob',
      code,
    });
  },
  promoteDataAdd(code, data) {
    return http.post('/marketing/marketingPromoteData', data, { code });
  },
  promoteDataUpdate(code, data) {
    return http.put('/marketing/marketingPromoteData', data, { code });
  },
  promoteDataDelete(code, params) {
    return http.delete('/marketing/marketingPromoteData', { params, code });
  },
  promoteUpdateStatus(code, data) {
    // 修改人员状态
    return http.put(`/marketing/marketingPromoteUser/updateStatus`, data, {
      code,
    });
  },
  promoteExportDataByPromoteId(code, id) {
    // 导出推广数据
    return http.get(
      `/marketing/marketingPromoteData/exportDataByPromoteId/${id}`,
      {
        responseType: 'blob',
        code,
      }
    );
  },

  // 推广链接式
  marketingLinkAdd(code, data) {
    return http.post('/marketing/marketingLink/save', data, { code });
  },
  marketingLinkUpdate(code, data) {
    return http.put('/marketing/marketingLink', data, { code });
  },
  marketingLinkSelect(code, promoteId) {
    return http.get(
      `/marketing/marketingLink/query/${promoteId}`,
      {},
      { code }
    );
  },

  // 资料
  ResourceCategoryVoList:
    '/resource/resourceCategory/queryAllResourceCategoryVoList',
  queryAllResourceCategoryVoList(code, params) {
    return http.get(
      `/resource/resourceCategory/queryAllResourceCategoryVoList`,
      { params, code }
    );
  },

  // 图表
  getChartData(code, params) {
    return http.get(`/marketing/marketingPromoteStat/getChartData`, {
      params,
      code,
    });
  },

  // 租户微信配置
  getFindUpdateChannel() {
    return http.get(`/issmart-messages/messageWechatChannel/findUpdateChannel`);
  },
  getMessageWechatChannel() {
    return http.get(
      `/issmart-messages/messageWechatChannel/getMessageWechatChannel`
    );
  },

};
