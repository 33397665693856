<template>
  <div class="iss-main-grid" ref="mainRef">
    <div class="iss-search-wrap">
      <div>
        <ul class="data-board">
          <li>
            <div>名片总数（人）</div>
            <div>{{ dataOverview.total }}</div>
          </li>
          <li>
            <div>今日新增（人）</div>
            <div>{{ dataOverview.todayCount }}</div>
          </li>
          <li>
            <div>本周新增（人）</div>
            <div>{{ dataOverview.weekCount }}</div>
          </li>
          <li>
            <div>本月新增（人）</div>
            <div>{{ dataOverview.monthCount }}</div>
          </li>
        </ul>
      </div>
      <search-form
        :items="items"
        :layout="{ sm: 24, md: 12, lg: 8 }"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />
    </div>
    <div class="iss-grid-wrap mt-20">
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >

      </grid>
    </div>
  </div>
</template>

<script>
import Grid from '@/components/grid';
import cardApi from '@/api/card'
import { reactive, ref, toRefs, } from 'vue';
import SearchForm from '@/components/searchForm';

export default {
  components: {
    Grid,
    SearchForm,
  },
  setup() {
    const gridRef = ref();

    const state = reactive({
      search: { userName: '', companyName: '', mobileNum: '' },
      dataOverview: {
        weekCount: '',
        total: '',
        todayCount: '',
        monthCount: '',

      },
    });

    // 数据总览接口
    cardApi.dateOverviewe('', {}).then(records => {
      state.dataOverview = records;
    });

    return {
      ...toRefs(state),
      items: [
        { key: 'userName', label: '姓名' },
        { key: 'companyName', label: '公司' },
        { key: 'mobileNum', label: '手机' },
      ],
      columns: [
        { dataIndex: 'userName', title: '姓名', width: 80, },
        { dataIndex: 'wechatAccount', title: '微信号', width: 100, },
        { dataIndex: 'email', title: '邮箱', width: 150 },
        { dataIndex: 'mobileNum', title: '手机', width: 120 },
        { dataIndex: 'companyName', title: '公司', width: 80 },
        { dataIndex: 'position', title: '职位', width: 80, },
        { dataIndex: 'createTime', title: '创建时间', width: 120, },

      ],
      //添加按钮
      btnOperation: [],

      handleFnSearch: value => {
        state.search = value;
      },

      url: cardApi.pageUrl,
      gridHeight: document.body.clientHeight - 460,
      gridRef,
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main-grid {
  height: calc(100vh - 111px);
  overflow: auto;
}
.modal {
  .ant-btn {
    width: 85%;
  }
  }
.not-open {
  text-align: center;
  padding: 100px 0px 100px 0px;
}
.qrcode-ver {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.qrcode-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #5979f8;
  border-color: #5979f8;
}
.vue-qr {
  .qr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.item-content_demo {
  /*height:670px;*/
  /* width:375px;*/
  height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    /*height: 100%;*/
  }
  .qrcode {
    position: absolute;
  }
}
.iss-search-wrap {
  .data-board {
    border-bottom: 1px #e5e6eb solid;
    padding-bottom: 12px;
    display: flex;
    li {
      width: 33.33%;
      & > div:nth-of-type(1) {
        margin-bottom: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1d2129;
      }
      & > div:nth-of-type(2) {
        font-size: 18px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #1d2129;
      }
    }
  }
}
.iss-main-grid /deep/ .ant-space-item {
  padding-bottom: 20px;
}
</style>
